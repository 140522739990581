import $ from 'jquery';
window.$ = window.jQuery = $;
require('jquery-validation');

jQuery(function () {
    // jQuery("body").css("color", "blue");
});

(function ($) {
    require("./simple.js");
    require("./addition.js");
    require("./forms.js");
}(jQuery));