$("#email_form").validate({
    errorElement: 'span',
    // errorClass: 'span__error',
    // ignoreTitle: true,
    //   errorPlacement: function(error, element) {
    //       error.appendTo($(element).parents('.input-block'));
    //   },
    //   highlight: function(element) {
    //       $(element).parents('.input-wrapper').addClass("forms__error");
    //   },
    //   unhighlight: function(element) {
    //       $(element).parents('.input-wrapper').removeClass("forms__error");
    //   },
    submitHandler: function (form) {
      var formdata = $(form).serialize();
  
      setTimeout(function () {
        $("#email_form .btn").prop("disabled", true);
        $("#email_form .form__success").addClass("active");
      }, 500);
      setTimeout(function () {
        $("#email_form .form__success").removeClass("active");
        $("#email_form .btn").prop("disabled", false);
      }, 4000);
  
      $.post(window.location.origin + "/backend.php", formdata, function (data) {
        switch (data.message) {
          case "error":
            $("#reg_form .modal__auth-error").slideDown(200);
            break;
          case "success":
            $("#submit_register").addClass('hide');
            $("#reg_form .form__loader").removeClass('hide');
            break;
        }
      }, 'json');
  
      return false;
    }
  });
  
  // $("#feedback").validate({
  //   errorElement: 'span',
  //   submitHandler: function (form) {
  //     var formdata = $(form).serialize();
  //     $('#feedback').trigger("reset");
  //     // formdata.reset();
  
  //     setTimeout(function () {
  //       $("#feedback .form__btn").prop("disabled", true);
  //       $("#feedback .form__success").addClass("active");
  //     }, 500);
  //     setTimeout(function () {
  //       $("#feedback .form__success").removeClass("active");
  //       $("#feedback .form__btn").prop("disabled", false);
  //     }, 4000);
  
  //     $.post(window.location.origin + "/backend.php", formdata, function (data) {
  //       switch (data.message) {
  //         case "error":
  //           $("#reg_form .modal__auth-error").slideDown(200);
  //           break;
  //         case "success":
  //           $("#submit_register").addClass('hide');
  //           $("#reg_form .form__loader").removeClass('hide');
  //           break;
  //       }
  //     }, 'json');
  //   }
  // });
  
  $("#feedback").validate({
    errorElement: 'span',
    submitHandler: function (form) {
      var formdata = $(form).serialize();
      $('#feedback').trigger("reset");
      $("#feedback .form__btn").hide();
      $("#feedback .form__success").show();
  
      setTimeout(function () {
        $("#feedback .form__btn").show();
        $("#feedback .form__success").hide();
        $('.overlay').removeClass('is-open');
      }, 4000);
      
      $.ajax({
        type: "POST",
        // url: window.location.origin + "/backend.php",
        url: window.location.href + "/backend.php",
        data: formdata,
        success: function (msg) {
          // if (msg == 'OK') {
          //   $(".form-loader").hide();
          //   $(".form-success").show();
  
          //   setTimeout(function () {
          //     $(".form-success").hide();
          //     $(".wpforms-submit").show();
          //   }, 4000);
          // } else {
          //   $(".form-success").hide();
          //   $(".form-loader").hide();
          //   $(".wpforms-submit").show();
          // }
        }
      });
      return false;
  
    }
  });
  