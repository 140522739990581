"use strict";
! function (e) {
    e(".tabs__titles>div").on("mouseenter", (function () {
            var t = e(this).index() + 1;
            e(this).parent().find("div").removeClass("tab-selected"),
                e(this).addClass("tab-selected"),
                e(this).parents(".tabs").find(".tabs__body>div").removeClass("tab-selected"),
                e(this).parents(".tabs").find(".tabs__body>div:nth-child(" + t + ")").addClass("tab-selected")
        })),
        e(".solution--tab").on("click", (function () {
            e(this).toggleClass("active")
        })),
        e(".goal").on("mouseenter", (function () {
            var t = e(this).index() + 1;
            1 === t ? (e(".goal").removeClass("active"),
                e(this).addClass("active"),
                e(".child").removeClass("active"),
                e(".child:nth-child(1)").addClass("active")) : 2 === t ? (e(".goal").removeClass("active"),
                e(this).addClass("active"),
                e(".child").removeClass("active"),
                e(".child:nth-child(6)").addClass("active")) : 3 === t && (e(".goal").removeClass("active"),
                e(this).addClass("active"),
                e(".child").removeClass("active"),
                e(".child:nth-child(11)").addClass("active"))
        })),
        e(".navigation-btn").on("click", (function (t) {
            e(".navigation").toggleClass("navigation--show"),
                e(this).toggleClass("active"),
                e("body").toggleClass("overlay")
        })),
        e(".navigation").on("click", (function () {
            e(this).removeClass("navigation--show"),
                e(".navigation-btn").removeClass("active")
        })),
        e(window).resize((function () {
            e(this).width() >= 1200 && (e(".navigation").removeClass("navigation--show"),
                e(".navigation-btn").removeClass("active"))
        })),
        e(".careers-video figure").on("click", (function () {
            var t = e(this).parents(".careers-video"),
                i = e(t).attr("id"),
                n = e('<iframe src="https://www.youtube.com/embed/' + i + '?rel=0&amp;autoplay=1" frameborder="0" allowfullscreen></iframe>');
            e(t).find("img").hide(),
                e(t).find("figure").addClass("show-video").append(n)
        })),
        e("#fileuploadfield").on("change", (function () {
            var t = e("#fileuploadfield").val().split("\\").pop();
            if (t.length > 18) {
                var i = t.split(".")[1];
                t.split(" ").length > 1 && (e(".resumename").text(t.split(" ")[1].split("").slice(0, 10).join("") + "...." + i),
                        e("#fileuploadfield").blur()),
                    e(".resumename").text(t.split("").slice(0, 10).join("") + "...." + i),
                    e("#fileuploadfield").blur()
            } else
                e(".resumename").text(t),
                e("#fileuploadfield").blur()
        })),
        e(".start_plan_project_block a").on("mouseenter", (function () {
            e(".start_plan_project_block a").removeClass("active"),
                e(this).addClass("active")
        })),
        jQuery(document).ready((function () {
            function t() {
                var e = {},
                    t = "",
                    n = "";
                try {
                    if (document.cookie.length > 0)
                        -
                        1 != (n = document.cookie.indexOf("__utmz=")) && null != n && (n = n + "__utmz".length + 1,
                            c_end = document.cookie.indexOf(";", n),
                            -1 == c_end && (c_end = document.cookie.length),
                            t = unescape(document.cookie.substring(n, c_end)));
                    if ("" != t) {
                        var s = t.split("|");
                        for (i = 0; i < s.length; i++)
                            s[i].indexOf("utmcsr=") >= 0 && (e.ga_source = s[i].substring(s[i].indexOf("=") + 1)),
                            s[i].indexOf("utmccn=") >= 0 && (e.ga_campaign = s[i].substring(s[i].indexOf("=") + 1)),
                            s[i].indexOf("utmcmd=") >= 0 && (e.ga_medium = s[i].substring(s[i].indexOf("=") + 1)),
                            s[i].indexOf("utmcct=") >= 0 && (e.ga_content = s[i].substring(s[i].indexOf("=") + 1)),
                            s[i].indexOf("utmctr=") >= 0 && (e.ga_term = s[i].substring(s[i].indexOf("=") + 1))
                    }
                } catch (e) {
                    console.log(e)
                }
                return e
            }

        }))
}(jQuery);
var header = document.querySelector(".header"),
    sectionOne = document.querySelector("header"),
    sectionOneOptions = {
        rootMargin: "0px 0px 0px 0px"
    },
    sectionOneObserver = new IntersectionObserver((function (e, t) {
        e.forEach((function (e) {
            e.isIntersecting ? header.classList.remove("scrolled") : header.classList.add("scrolled")
        }))
    }), sectionOneOptions);
if (sectionOneObserver.observe(sectionOne),
    null !== document.querySelector(".home")) {
    var numbers = document.querySelector(".stats"),
        numbersOptions = {
            rootMargin: "-300px 0px 0px 0px"
        },
        numbersObserver = new IntersectionObserver((function (e, t) {
            e.forEach((function (e) {
                jQuery(".count").each((function () {
                    var e = jQuery(this);
                    jQuery({
                        Counter: 0
                    }).animate({
                        Counter: e.attr("id")
                    }, {
                        duration: 1e3,
                        easing: "swing",
                        start: function () {
                            e.hasClass("plus") && e.removeClass("plus-sign")
                        },
                        step: function (t) {
                            e.text(Math.ceil(t))
                        },
                        complete: function () {
                            e.hasClass("plus") && e.addClass("plus-sign")
                        }
                    })
                }))
            }))
        }), numbersOptions);
        if (numbersObserver && numbers) {
            numbersObserver.observe(numbers)
        }
}
jQuery("#dreamJob > input").attr("value", "Submit");
var sliderPost = document.querySelector(".post-wrapper");
sliderPost && jQuery(".post-wrapper").slick({
    slidesToShow: 4,
    slidesToScroll: 2,
    autoplay: !0,
    autoplaySpeed: 2e3,
    responsive: [{
        breakpoint: 1281,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 2
        }
    }, {
        breakpoint: 610,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1
        }
    }]
})

// window.addEventListener("DOMContentLoaded", (function () {
//     var e = document.querySelector("#font-btn"),
//         t = document.querySelector("#font-btn2"),
//         i = document.querySelector("#voices"),
//         n = document.querySelector("#voiceOn"),
//         s = document.querySelector(".voice-trigger"),
//         o = document.querySelector(".voice-panel");
//     n.addEventListener("change", (function () {
//         if (this.checked) {
//             console.log("checked");
//             try {
//                 (t = window.speechSynthesis || window.mozspeechSynthesis || window.webkitspeechSynthesis).onvoiceschanged = function () {
//                         e = t.getVoices(),
//                             console.log(e);
//                         for (var n = 0; n < e.length; n++)
//                             i.innerHTML += '<option lang="'.concat(e[n].voiceURI, '" value="').concat(n, '">').concat(e[n].voiceURI, "</option>")
//                     },
//                     function (e) {
//                         var t = e,
//                             n = t.length;
//                         if (null != t)
//                             document.addEventListener("click", (function (e) {
//                                 for (var s, o, a, c, r, l, d, u, v, h, g = e.target, m = 0; m < n; m++)
//                                     e.target.tagName === t[m] && (window.speechSynthesis.cancel(),
//                                         s = {
//                                             data: g.textContent
//                                         },
//                                         o = void 0,
//                                         a = void 0,
//                                         c = void 0,
//                                         r = void 0,
//                                         l = void 0,
//                                         d = void 0,
//                                         u = void 0,
//                                         v = void 0,
//                                         h = void 0,
//                                         o = s.data,
//                                         a = s.pitch,
//                                         c = void 0 === a ? 1 : a,
//                                         r = s.speed,
//                                         l = void 0 === r ? 1 : r,
//                                         d = s.sound,
//                                         u = void 0 === d ? 2 : d,
//                                         v = new SpeechSynthesisUtterance(o),
//                                         h = window.speechSynthesis.getVoices(),
//                                         v.voice = h[+i.value],
//                                         v.pitch = c,
//                                         v.rate = l,
//                                         v.volume = u,
//                                         window.speechSynthesis.speak(v))
//                             }))
//                     }(["P", "SPAN", "H1", "H2", "H3", "H4", "A", "BUTTON", "B", "LABEL", "DIV"])
//             } catch (e) {
//                 console.log(e)
//             }
//         } else
//             console.log("unchecked"),
//             location.reload();
//         var e, t
//     }));
//     var a = {
//         count: 18
//     };
//     e.addEventListener("click", (function () {
//             document.body.style.fontSize = (a.count += 2) + "px"
//         })),
//         t.addEventListener("click", (function () {
//             document.body.style.fontSize = (a.count -= 2) + "px"
//         })),
//         s.addEventListener("click", (function () {
//             o.classList.toggle("active")
//         })),
//         document.addEventListener("keydown", (function (e) {
//             "Escape" === e.key && o.classList.remove("active")
//         }))
// }));